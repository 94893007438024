import React, { Component } from 'react';
import { Button, Modal, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import S3 from 'aws-sdk/clients/s3';
import uuid from 'react-uuid';

import Aws from './aws';
import Config from './config';

class Session extends Component {
  constructor(props){
    super(props);
    this.state = {
      files: [],
      files_status: [],
      status: 'check',   // download | check | update | complete
      processing: false,
      confirm: false,
      error_status: '',  // server | get_archive | get_result
    }

    this.confirm = this.confirm.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    
    this.downloadObject = this.downloadObject.bind(this);
    this.readObject = this.readObject.bind(this);

    this.invoke = this.invoke.bind(this);
    this.download = this.download.bind(this);
    this.upload = this.upload.bind(this);
    this.validate = this.validate.bind(this);
    this.update = this.update.bind(this);

    this.delay = 10000;   // 10000 ms
    this.retryCount = 9;  // 9
  }

  componentDidMount() {
    if (Config.config.lambda) {
      this.s3 = new S3(Aws.s3.credentials);
    }
  }

  confirm() {
    this.setState({
      confirm: true
    })
  }
  closeConfirm() {
    this.setState({
      confirm: false
    })
  }

  handleOnDrop(files) {
    let accepted_files = files.filter((x)=>this.state.files.map((f)=>f.name).indexOf(x.name)===-1);
    accepted_files.map((x) => {
      x.id = uuid();
      return null;
    })
    this.setState({
      files: [...this.state.files, ...accepted_files],
      status: 'check',
    });
  }
  removeItem(filename) {
    let files = [...this.state.files].filter(x=>x.name!==filename);
    let files_status = [...this.state.files_status].filter(x=>x.name!==filename);
    this.setState({
      files: files,
      files_status: files_status,
      status: 'check',
    })
  }
  removeAll() {
    this.setState({
      files: [],
      files_status: [],
      status: 'check'
    })
  }


  retryPromise(func, arg, delay = this.delay, retryCount = this.retryCount) {
    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        func(arg, resolve, reject);
      }, delay);
    })
    for (let i = 0; i < retryCount; i++) {
      promise = promise.catch(() => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            console.log(`retry ${i+1}`);
            func(arg, resolve, reject);
          }, delay);
        });
      });
    }
    return promise;
  }

  downloadObject(arg, resolve, reject) {
    this.s3.getObject(arg.data, (err, s3_obj) => {
      try {
        const blob = new Blob([s3_obj.Body], { type: s3_obj.ContentType })
        const a = document.createElement('a');
        a.download = 'backup_files.zip';
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.setState({ 
          processing: false,
          status: 'check',
        });
        resolve();
      } catch(e) {
        reject();
      }
    })
  }

  readObject(args, resolve, reject) {
    this.s3.getObject(args.data, (err, s3_obj) => {
      try {
        const response = JSON.parse(s3_obj.Body.toString()).res;
        if (args.target==='validate') {
          this.setState({
            id: args.id,
            files_status: response,
            status: response.map(x=>{return x.df_check===null ? false : x.df_check.uniq_id&&x.df_check.complete_cell&&x.df_check.dry_run})
                            .indexOf(false)===-1 ? 'update' : 'check',
            processing: false,
          })
        } else if (args.target==='update') {
          this.setState({
            files_status: response,
            status: 'complete',
            processing: false,
          })
        }
        resolve();
      } catch(e) {
        reject();
      }
    })
  }

  async invoke(target, id) {
    const { data } = await axios.post( Config.config.serverUrl + '/invoke',
                                        { target: target, id: id } )
    return data;
  }

  async download() {
    this.setState({ 
      processing: true,
      status: 'download',
      error_status: '',
    });
    if (Config.config.lambda) {
      const target = 'download';
      this.invoke(target)
        .then((res) => {
          this.retryPromise(this.downloadObject, {data: res})
            .catch((e) => {
              this.setState({ 
                processing: false,
                status: 'check',
                error_status: 'get_archive',
              });
            });
        })
        .catch((e) => {
          this.setState({ 
            processing: false,
            error_status: 'server',
          });
        })
    } else {
      try {
        const { data } = await axios.get( Config.config.serverUrl + '/download',
                                          {
                                            withCredentials: true,
                                            responseType: 'arraybuffer',
                                            headers: { Accept: 'application/zip' },
                                          })
        const blob = new Blob([data], { type: 'application/zip' })
        const a = document.createElement('a');
        a.download = 'backup_files.zip';
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.setState({ 
          processing: false,
          status: 'check',
        });
      } catch (e) {
        this.setState({ 
          processing: false,
          status: 'check',
          error_status: 'get_archive'
        });
      }
    }
  }

  async upload(target) {
    try {
      const formData = new FormData();
      this.state.files.map((x) => {
        formData.append(x.id, x);
        return null;
      })
      const { data } = await axios.post(  Config.config.serverUrl + '/' + target,
                                          formData,
                                          { withCredentials: true })
      return data.res;
    } catch (e) {
      this.setState({ 
        processing: false,
        error_status: 'server',
      });
    }
  }

  async validate() {
    const target = 'validate';
    this.setState({ 
      files_status: [],
      processing: true,
      error_status: '',
    });
    if (Config.config.lambda) {
      const id = uuid();
      let putObjectPromises = [];
      this.state.files.map((f) => {
        const params = { Bucket: Aws.s3.bucketname, Key: `upload/${id}/${f.name}`, ContentType: f.type, Body: f }
        let putObjectPromise = this.s3.putObject(params).promise();
        putObjectPromises.push(putObjectPromise);
        return null;
      })
      await Promise.all(putObjectPromises)
        .then(() => {
          this.invoke(target, id)
            .then((res) => {
              this.retryPromise(this.readObject, {target: target, data: res, id: id})
              .catch((e) => {
                this.setState({ 
                  processing: false,
                  error_status: 'get_result',
                });
              });
            })
            .catch((e) => {
              this.setState({ 
                processing: false,
                error_status: 'server',
              });
            })
        })
    } else {
      this.upload(target)
        .then((response) => {
          if (response) {
            this.setState({
              files_status: response,
              status: response.map(x=>{return x.df_check===null ? false : x.df_check.uniq_id&&x.df_check.complete_cell&&x.df_check.dry_run})
                              .indexOf(false)===-1 ? 'update' : 'check',
              processing: false,
            })
          }
        })
    }
  }

  async update() {
    const target = 'update';
    this.setState({ 
      processing: true,
      confirm: false,
      error_status: '',
    });
    if (Config.config.lambda) {
      this.invoke(target, this.state.id)
        .then((res) => {
          this.retryPromise(this.readObject, {target: target, data: res})
          .catch((e) => {
            this.setState({ 
              processing: false,
              error_status: 'get_result',
            });
          });
        })
        .catch((e) => {
          this.setState({ 
            processing: false,
            error_status: 'server',
          });
        })
    } else {
      this.upload(target, this.state.id)
        .then((response) => {
          if (response) {
            this.setState({
              files_status: response,
              status: 'complete',
              processing: false,
            })
          }
        })
    }
  }

  renderOverlay(content) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={ <Popover>
                    <Popover.Content>{content}</Popover.Content>
                  </Popover> }
      >
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path></svg>
      </OverlayTrigger>
    )
  }

  renderErrorDetail() {
    if (this.state.files_status.length > 0 && this.state.files_status[0].update===undefined) {
      const detail = this.state.files_status.filter((x) => x.df_check!==null && Object.keys(x.df_check.error_detail).length > 0)
      if (detail.length > 0) {
        return (
          <div className="error_detail">
            <div>エラー詳細</div>
            { detail.map((x,i) => {
              return (
                <div key={i}>
                  <div>{x.filename}</div>
                    { Object.keys(x.df_check.error_detail).map((detail,ii) => {
                      let msg;
                      if      (detail === 'uniq_id')             { msg = 'ID要素が重複しています。' }
                      else if (detail === 'complete_cell')       { msg = '空白の要素があります。' }
                      else if (detail === 'qid_mismatch')        { msg = '問題データが存在しません。' }
                      else if (detail === 'wrong_choices_count') { msg = '正答番号に対して選択肢が不足しています。' }
                      else if (detail === 'wrong_answer')        { msg = '正答内容と正答番号が一致しません。' }
                      return (
                        <div key={ii}>
                          <div className="detail">{msg}</div>
                          { x.df_check.error_detail[detail].map((row,j) => <div key={j}>{row}</div>) }
                        </div>
                      )
                    })}
                </div>
              )
            })}
          </div>
        )
      }
    }
  }

  renderTableHeader() {
    if (this.state.status==='complete') {
      return (
        <div className="table_ header_ table_complete">
          <div><span>ファイル</span></div>
          <div><span>データベース更新結果</span></div>
        </div>
      )
    } else {
      return (
        <div className="table_ header_">
          <div>
            <button className="btn_close btn_close_file" onClick={this.removeAll.bind(this)}><div><span></span></div></button>
            <span>ファイル</span><span>{this.renderOverlay(`有効なファイルは ${this.props.filenames.join(', ')}。ファイル名の重複は無効。アップロードは一部のファイルのみでも可能。`)}</span>
          </div>
          <div><span>ファイル名</span><span>{this.renderOverlay('ファイル名が正しいか')}</span></div>
          <div><span>カラム数</span><span>{this.renderOverlay('カラムの数が正しいか')}</span></div>
          <div><span>読み込み</span><span>{this.renderOverlay('サーバ側でのデコード結果')}</span></div>
          <div><span>一意ID</span><span>{this.renderOverlay('IDとなる要素が一意であるか')}</span></div>
          <div><span>空白要素</span><span>{this.renderOverlay('空白の要素がないか')}</span></div>
          <div><span>変換</span><span>{this.renderOverlay('インポート用データへの変換結果（変換に失敗し、詳細が表示されない場合はデータの型変換が失敗した可能性があります。）')}</span></div>
        </div>
      )
    }
  }

  renderTableContents() {
    return (
      this.state.files.map((x,i) =>
        <div className={this.state.status==='complete' ? "table_ row_ table_complete" : "table_ row_"} key={i}>
          <div>
              {this.state.status!=='complete' && <button className="btn_close btn_close_file" onClick={this.removeItem.bind(this,x.name)}><div><span></span></div></button>}
              <span className="upload_filename">{x.name}</span>
          </div>
          { this.renderStatus(x.name) }
        </div>
      )
    )
  } 

  renderStatus(filename) {
    if (this.state.files_status.length > 0) {
      let result = this.state.files_status.filter(x=>x.name===filename);
      if (result.length > 0) {
        result = result[0]
        if (result.update!==undefined) {
          return (
            <div>{result.update ? '成功' : '失敗'}</div>
          )
        } else {
          return (
            <>
              <div>{result.data_check.valid_filename ? '○' : '×'}</div>
              <div>{result.data_check.valid_filename ? (result.data_check.valid_columns ? '○' : '×') : '-'}</div>
              <div>{result.data_check.decoded ? '○' : '×'}</div>
              <div>{result.df_check===null ? '-' : result.df_check.uniq_id ? '○' : '×'}</div>
              <div>{result.df_check===null ? '-' : result.df_check.complete_cell ? '○' : '×'}</div>
              <div>{result.df_check===null ? '-' : result.df_check.dry_run ? '○' : '×'}</div>
            </>
          )
        }
      }
    }
  }

  renderProcessing() {
    let process;
    if      (this.state.status==='download')  { process = 'ダウンロード中' }
    else if (this.state.status==='check')     { process = '検証中' }
    else if (this.state.status==='update')    { process = '更新中' }
    return (
      this.state.processing&&
        <div className="spinner">
          <Spinner animation="grow" variant="secondary"></Spinner>
          <span>{process}...</span>
        </div>
    )
  }

  renderUpload() {
    return (
      <div className="upload">
      <div className="upload_container">
         <div className="dropzone">
           <Dropzone onDrop={acceptedFiles => this.handleOnDrop(acceptedFiles)}
                     multiple={true}
                     disabled={this.state.status==='complete'}>
             {({getRootProps, getInputProps}) => (
               <div {...getRootProps()}>
                 <input {...getInputProps()} />
                 <div className="droparea_text">
                   <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-csv" className="svg-inline--fa fa-file-csv fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path></svg>
                   <div>
                     <span>ファイルをドラッグ</span>
                     <span>またはクリックして選択</span>
                   </div>
                 </div>
               </div>
             )}
           </Dropzone>
         </div>
         <div className="buttons">
            <Button variant="info" onClick={this.download} className="btn_download">バックアップファイルのダウンロード</Button>
           { this.state.files.length > 0 &&
             <>
               <Button variant="info" onClick={this.validate} disabled={this.state.status==='complete'}>ファイルチェック実行</Button>
               <Button variant="info" onClick={this.confirm} disabled={this.state.status!=='update'}>データベース更新</Button>
               <Button variant="outline-info" onClick={this.removeAll.bind(this)}>アップロードしなおす</Button>
             </>
           }
         </div>
       </div>

       { this.state.files.length > 0 &&
         <>
          <div className="upload_files">
            { this.renderTableHeader() }
            { this.renderTableContents() }
          </div>
           { this.state.status==='check' && this.renderErrorDetail() }
         </>
       }
     </div>
    )
  }

  render() {
    return (
      <div className="wrapper">
        <h1 className="title">{Config.config.app==='recommend' ? 'レコメンドアプリ' : 'トレーニングアプリ'}データ更新システム</h1>
 
        <div className="error_msg">
          { this.state.error_status==='server'      && <span>サーバーへのアクセスに失敗しました。</span> }
          { this.state.error_status==='get_archive' && <span>ファイルの取得に失敗しました。</span> }
          { this.state.error_status==='get_result'  && <span>結果の取得に失敗しました。</span> }
        </div>

        { this.renderUpload() }

        <Modal show={this.state.processing}
               onHide={()=>{return null}}
               dialogClassName="modal_processing"
               centered >
          { this.renderProcessing() }
        </Modal>


        <Modal show={this.state.confirm}
               onHide={this.closeConfirm}
               dialogClassName="modal_confirm"
               centered >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <p>データベースを更新します。よろしいですか？</p>
            <p>この操作は元に戻せません。</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-info" onClick={this.closeConfirm}>いいえ</Button>
            <Button variant="info" onClick={this.update}>はい</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default Session;
